<div class="consentbox d-flex align-items-center h-100" *ngIf="!showThankYou">
  
  <div class="innerbox">
    <h2>Consent Form</h2>
    <h4>Your Agreement</h4>
  
    <p>
      I/We hereby authorise and provide my consent to Shield or its designated financial institutions or partners:
    </p>
    <ul>
      <li>To access and obtain both hard and soft copies of all my/ our documents, reports, and other such information ('Credit Information) maintained with the Credit Information Bureau (India) Limited ("CIBIL"), Experian, Equifax or any other credit agency. Accordingly, V we hereby authorize Shield to access my/our Credit Information Report from CIBIL, Experian, Equifax or any other credit agency.</li>
      <li>To send me/us communications regarding loan/credit facility and other products from Shield and/or third parties through call/ SMS/ WhatsApp/ email/ notification communication etc. irrespective of rejection of my/ our loan/credit facility application.</li>
      <li>To validate my/our employment/occupation details using Mobile Number, Email ID etc.</li>
      <li>To read and verify my/our profile details using PAN, GST, Aadhaar, Passport, Voter ID, Driving License, Utility Bills or any other valid identity or address proof.</li>
      <li>To conduct search/download/Update/disclose my/our KYC records from/to Central KYC registry by using KYC identifier.</li>
      <li>To access and verify any other information required for processing loan/ credit facility application.</li>
      <li>To collect my/ our Credit Information and submit my/ our application form(s) to appropriate financial agencies.</li>
      <p>I/We acknowledge that the credit information obtained by Shield shall be maintained and used only in the manner provided in the Terms & Conditions of loan/credit facility and all applicable policies of Shield.</p>
      <p>I/We shall be solely liable and shall indemnify & hold harmless Shield for and against any third-party claims and/or damages of any kind, arising from or in connection with any misrepresentations, incorrect information or omission of any critical information in my/ our application.</p>
      <p>I/We shall always co-operate with Shield and execute and deliver to them such instruments and documents and take such other actions as may be required by them, from time to time, in connection with this consent form.</p>
      <p>I/we hereby authorize Shield to verify all the information provided by me/ us in the application form. I/We hereby acknowledge that grant of loan/credit facility is subject to satisfactory verification of the provided information.</p>
    </ul>
    <div class="consent-cta-box d-flex align-items-center justify-content-end">
      <button (click)="cancel()">Cancel</button>
      <button class="agree-cta" (click)="consent()">Agree</button>
    </div>
  </div>
</div>
<div class="consentbox d-flex align-items-center h-100" *ngIf="showThankYou">
  <div class="innerbox">
    <h2>Thank You</h2>
    <h4>Please close the window for security reasons.</h4>
    </div>
</div>
