import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { urls } from '../../url'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AuthService } from 'src/app/auth/auth.service';
import { params } from 'src/app/param';
@Injectable({
  providedIn: 'root'
})
export class KanbanService {
  readonly BaseUrl;
  totalRecords: any;
  isTabChanageFromTile: boolean = false;
  refreshKboard = new BehaviorSubject<Boolean>(false);
  isVisibleLanes: EventEmitter<any> = new EventEmitter<any>();
  gstNoObservable:EventEmitter<any> = new EventEmitter<any>();
  laneTaskId = new BehaviorSubject<any>('');
  laneData = new BehaviorSubject<any>({});
  currentLaneId = new BehaviorSubject<any>({});
  laneListIds = new BehaviorSubject<any>({});
  hasAccess = new BehaviorSubject<any>(false);
  count = 0;
  selectedColdLeadReason: string;
  selectedReVivalReasons: string;
  isBuyer:EventEmitter<any> = new EventEmitter<any>();;
  LeadLanes = [{laneName: "New Leads",position:1,routePath: "new-leads"},{laneName: "Details & Documentation",position:2,routePath: "application-documents-submitted"},{laneName: "GSTIN Analysis",position:3,routePath: "gstin-analysis"},{laneName: "Under Writing Doc.",position:4,routePath: "underwriting"}]
  constructor(private http: HttpClient, public authService: AuthService) {
    if (this.authService.isKanbanUser()) {
      this.BaseUrl = environment.MOGLIXKANBAN;
    }
    else {
      this.BaseUrl = environment.CREDLIXKANBAN
    }
  }
  readonly BaseCredlixUrl = environment.BASEURL;
  readonly EximURL = environment.EXIMAUTOSALES;


  showFile(id: any) {
    let url: string = this.BaseUrl + `${"documents/download-attachment"}`;
    let reqParams = new HttpParams();

    reqParams = reqParams.append("documentId", id);
    return this.http.get(url, {
      params: reqParams,
      responseType: "blob",
      observe: "response",
    });
  }

  deleteFolderFile(documentId) {
    let url: string = this.BaseUrl + `${"documents"}`;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('documentId', documentId);
    return this.http.delete(url, {
      params: reqParams,
    });
  }
  getDocuments(id: any) {
    let url: string = this.BaseUrl + `${"documents/get-folders"}`;
    let reqParams = new HttpParams();
    reqParams = reqParams.append("laneTaskId", id);
    return this.http.get(url, {
      params: reqParams
    })
  }
  uploadFolder(formData) {
    let url: string = this.BaseUrl + `${"documents/upload-folder"}`;
    let reqParams = new HttpParams();
    return this.http.post(url, formData, {
      params: reqParams
    });
  }
  uploadSingleDocument(userId, remarks, formData) {
    let url: string = this.BaseUrl + `${"documents/upload-attachment"}`;
    let reqParams = new HttpParams();
    reqParams = reqParams.append("laneTaskId", userId);
    reqParams = reqParams.append("remarks", remarks);
    return this.http.post(url, formData, {
      params: reqParams,
    });
  }
  setValue(value: any) {
    this.isVisibleLanes.emit(value);
  }
  setBuyerValue(value: any) {
    this.isBuyer.emit(value);
  }
  setLaneTaskId(id){
    this.laneTaskId.next(id);
  }
  setLaneData(data){
    this.laneData.next(data);
    
  }
  setCurrentLaneId(obj){
    this.currentLaneId.next(obj);
  }
  setLaneListIds(obj){
    this.laneListIds.next(obj)
  }
  setHasAccess(obj){
    this.hasAccess.next(obj)
  }
  fetchData(id:any){
    let url = this.BaseUrl + urls.fetchDomesticData;
    return this.http.get(url +'/'+id);
  }
  fetchByGst(gstNo){
    let url = this.BaseUrl + urls.fetchByGst;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('gstinNo',gstNo);
    reqParams = reqParams.append('isAddtionalData','false');
    return this.http.get(url,{
      params:reqParams
    })
  }
  fetchUsingCin(cinNo){
    let url = this.BaseUrl + urls.fetchByCin;
    let reqParms = new HttpParams();
    reqParms = reqParms.append('cinNo',cinNo);
    return this.http.get(url,{
      params: reqParms
    })
  }
  uploadSingleFile(laneTaskId,docType,value,file?,oldDocId?){
    let url = this.BaseUrl + urls.uploadSingleNonOcr;
    let formData = new FormData()
    formData.append('laneTaskId',laneTaskId);
    formData.append('docType',docType);
    formData.append('value',value);
    formData.append('file',file);
    if(oldDocId){
      formData.append('oldDocId',oldDocId);
    }
    return this.http.post(url , formData);
  }

  uploadDiscountRateProof(file,laneTaskId,doctype){
    let reqParams = new HttpParams()
    reqParams = reqParams.append('laneTaskId',laneTaskId);
    reqParams = reqParams.append('documentType',doctype);
    let url = this.BaseUrl + urls.uploadDiscountRateProof;
    let formData = new FormData()
    formData.append('file',file);
    return this.http.post(url , formData,{
      params: reqParams
    });
  }

  getDownloadedRoiFileDetails(lanetaskID){
    let reqParams = new HttpParams();
    reqParams = reqParams.append('id',lanetaskID);
    let url = this.BaseUrl + urls.getRoiUploadedFile;
    return this.http.get(url,{
      params:reqParams
    });
  }

  downloadROIFiles(docId){
    let reqParams = new HttpParams();
    reqParams = reqParams.append('documentId',docId);
    let url = this.BaseUrl + urls.downloadRoiDoc;
    return this.http.get(url,{
      params:reqParams,
      responseType: 'blob'
    });
  }

  uploadSingleBusinessFile(laneTaskId,docType,value,file,directorId,oldDocId){
    let url = this.BaseUrl + urls.uploadSingle;
    let formData = new FormData()
    formData.append('laneTaskId',laneTaskId);
    formData.append('docType',docType);
    formData.append('value',value);
    formData.append('file',file);
    if(directorId){
    formData.append('referenceId',directorId);}
    if(oldDocId){
      formData.append('oldDocId',oldDocId);
    }
    return this.http.post(url , formData);
  }
  uploadNonOcrFile(laneTaskId,docType,value,file,directorId,oldDocId){
    let url = this.BaseUrl + urls.uploadSingleNonOcr;
    let formData = new FormData()
    formData.append('laneTaskId',laneTaskId);
    formData.append('docType',docType);
    formData.append('value',value);
    formData.append('file',file);
    if(directorId){
    formData.append('referenceId',directorId);}
    if(oldDocId){
      formData.append('oldDocId',oldDocId);
    }
    return this.http.post(url , formData);
  }
  uploadBulkFiles(formData,laneTaskId,remark,header,refrenceId){
    let url = this.BaseUrl + urls.bulkUpload;
    let reqParams = new HttpParams()
    reqParams = reqParams.append('laneTaskId',laneTaskId);
    reqParams = reqParams.append('remarks',remark);
    reqParams = reqParams.append('header',header);
    if(refrenceId){
      reqParams = reqParams.append('referenceId',refrenceId);
    }
    return this.http.post(url,formData,{
      params : reqParams
    });
  }
  laneChangeCall(lanetaskId,laneId,toLandID){
    let url = this.BaseUrl + urls.changeLane;
    let obj={
      id:lanetaskId,
      laneId:laneId,
      toLaneID:toLandID
    }
    return this.http.post(url,obj);
  }
  sentForConsent(lanetaskID,payload){
    let url = this.BaseUrl + urls.sendForConsent;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('laneTaskId',lanetaskID);
    reqParams = reqParams.append('recepientIds',payload);
    return this.http.post(url,{},{
      params: reqParams
    })
  }
  generateOtp(token){
    let url = this.BaseUrl + urls.generateOtpUrls;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('token',token);
    return this.http.post(url,{},{
      params: reqParams
    })
  }
  verifyOtp(token,otp){
    let url = this.BaseUrl + urls.verifyOtp;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('token',token);
    reqParams = reqParams.append('otp',otp);
    return this.http.post(url,{},{
      params: reqParams
    })
  }
  fetchDataByPan(panNo){
    let url = this.BaseUrl + urls.fetchByPan;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('panNo',panNo);
    return this.http.get(url,{
      params: reqParams
    })
  }
  saveNewLead(formData:any,id:any){
    let url = this.BaseUrl + urls.saveNewLeadData +id;
    return this.http.put(url,formData);
  }
  saveDetailsAndDocuments(data,id){
    let url = this.BaseUrl + urls.saveDetailsAndDocument +id;
    return this.http.put(url,data);
  }
  showAllFiles(type,id,bankId){
    let url = this.BaseUrl + urls.showAll +id;
    let reqParams = new HttpParams();
    reqParams = reqParams.append('documentName',type);
    if(bankId){
    reqParams = reqParams.append('referenceId',bankId);
    }
    return this.http.get(url,{
      params:reqParams
    })
  }
  deleteFile(type,docId,id,referenceId){
    let url = this.BaseUrl + urls.showSingleFile+id;
    let reqParams = new HttpParams()
    reqParams = reqParams.append('documentId',docId);
    reqParams = reqParams.append('documentName',type);
    if(referenceId){
      reqParams = reqParams.append('referenceId',referenceId);
    }
    return this.http.put(url,{},{
      params:reqParams
    })
  }
  reUploadFile(laneTaskId,dataType,file,oldId,referenceId){
    let url = this.BaseUrl + urls.replace;
    let formData = new FormData();
    formData.append('laneTaskId',laneTaskId);
    formData.append('docType',dataType);
    formData.append('file',file);
    formData.append('oldDocId',oldId);
    if(referenceId){
      formData.append('referenceId',referenceId);
    }
    return this.http.post(url,formData);
  }
  getListing(id){
    let url = this.BaseUrl + urls.underWrittingListing+id;
    return this.http.get(url);
  }
  updateLaneDetails(obj) {
    let url = this.BaseUrl + urls.updateLeadDetails
    return this.http.put(url, obj);
  }
  changeStatus(obj){
    let url = this.BaseUrl + urls.statusChange;
    return this.http.put(url,obj);
  }
  saveAdditionalInformation(obj) {
    let url = this.BaseUrl + urls.saveAdditional
    return this.http.put(url, obj);
  }

  selectMilestone(obj) {
    let url = this.BaseUrl + urls.changeMilestone
    return this.http.post(url, obj);
  }

  searchLeads(obj) {
    let url = this.BaseUrl + urls.searchLeads;
    return this.http.post(url, obj);
  }

  searchLeadsForCredit(obj) {
    let url = this.BaseUrl + urls.creditLeadTracker;
    return this.http.post(url, obj);
  }

  getLeadsList(pageNo, pageSize, laneId?) {
    let url = '';
    if (laneId) {
      url = `${this.BaseUrl + urls.getLeadsList}?pageNo=${pageNo}&pageSize=${pageSize}&laneTaskId=${laneId}`;
    }
    else {
      url = `${this.BaseUrl + urls.getLeadsList}?pageNo=${pageNo}&pageSize=${pageSize}`;
    }
    return this.http.get(url);
  }

  getBoardList() {
    let url = this.BaseUrl + urls.boardList
    return this.http.get(url);
  }

  getBoardLane(obj) {
    let url = this.BaseUrl + urls.searchLane
    return this.http.post(url, obj);
  }

  getCountryRating() {
    let url = this.BaseUrl + urls.COUNTRY_RATING
    return this.http.get(url);
  }

  getBoardStats(obj) {
    let url = this.BaseUrl + urls.laneTaskStats
    return this.http.post(url, obj);
  }
  getLaneWithUserAccess(id, userID, pageSize?, pageFrom?) {
    let url = this.BaseUrl + urls.fetchLaneWithAccess + "/" + id;
    if (userID != null && userID != '') {
      url = url + "?userId=" + userID
    }
    if (pageSize != null && pageFrom != null) {
      url = url + "?pageNo=" + pageFrom + '&pageSize=' + pageSize
    }
    return this.http.get(url);
  }
  createLaneTask(obj) {
    let url = this.BaseUrl + urls.createLanetask
    return this.http.post(url, obj);
  }
  getLaneTask(obj) {
    let url = this.BaseUrl + urls.laneTask
    return this.http.post(url, obj);
  }
  assignLaneTaskMovement(obj) {
    let url = this.BaseUrl + urls.assignLaneTask
    return this.http.post(url, obj);
  }
  removeAssignee(obj) {
    let url = this.BaseUrl + urls.removeAssignee + '?laneTaskId=' + obj.laneTaskId + '&assigneeIds=' + obj.assigneeIds
    return this.http.delete(url, obj);
  }
  removeWatchers(obj) {
    let url = this.BaseUrl + urls.removeWatchers + '?laneTaskId=' + obj.laneTaskId + '&watcherIds=' + obj.watcherIds
    return this.http.delete(url, obj);
  }

  removeAssignedtags(tagId, id) {
    let url = this.BaseUrl + urls.removeTags + '/lane-task/' + id + '/tag/' + tagId
    return this.http.delete(url);
  }
  updateLaneTask(obj) {
    let url = this.BaseUrl + urls.createLanetask
    return this.http.put(url, obj);
  }

  getLeadCommentsList(obj) {
    let params = new HttpParams();
    params = params.append('leadTrackerId', obj.leadTrackerId);
    let url = this.BaseUrl + urls.getLOSComment;
    return this.http.get(url, { params });
  }
  losTracketComment(obj) {
    let url = this.BaseUrl + urls.losTrackerComment;
    return this.http.post(url, obj);
  }

  detailsByLaneId(id) {
    let url = this.BaseUrl + urls.createLanetask + "/" + id
    return this.http.get(url);
  }

  getUserList() {
    let url = this.BaseCredlixUrl + urls.getUserManagement + '?authorities=' + 'KANBAN';
    return this.http.get(url);
  }
  addAssigneeToLaneTask(obj, id) {
    let url = this.BaseUrl + urls.addAssigneToLaneTask + '?laneTaskId=' + id
    return this.http.post(url, obj);
  }
  addLeadOwnerToLaneTask(obj) {
    let url = this.BaseUrl + urls.leadOwner
    return this.http.post(url, obj);
  }
  addWatchersToLaneTask(obj, id) {
    let url = this.BaseUrl + urls.addWatchers + '?laneTaskId=' + id
    return this.http.post(url, obj);
  }
  checkLaneMovementValidation(obj) {
    let url = this.BaseUrl + urls.laneMovementValidate
    return this.http.post(url, obj);
  }

  getAllTags(id) {
    let url = this.BaseUrl + urls.getTags + '?boardId=' + id
    return this.http.get(url);
  }

  getLaneConfigById(id) {
    let url = this.BaseUrl + urls.laneConfig + "/" + id
    return this.http.get(url);
  }

  uploadTaskDocuments(obj, id) {
    let url = this.BaseUrl + urls.laneTaskDocumentsUpload + '?laneTaskId=' + id + '&remarks=' + 'sampleRemarks'
    return this.http.post(url, obj);
  }
  deleteUploadedDocuments(id, fieldName, laneID, dataType) {
    let url = this.BaseUrl + urls.deleteTaskDocuments + '?documentId=' + id
    if (fieldName != null && laneID != null) {
      url = url + "&fieldName=" + fieldName + '&laneTaskId=' + laneID
    }
    if (dataType != null) {
      url = url + "&dataType=" + dataType
    }
    return this.http.delete(url);
  }

  downloadZipDocumentsByLaneId(id) {
    let url = this.BaseUrl + urls.downloadZipTaskDocuments + '?laneTaskId=' + id
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  downloadSingleUploadDocument(id) {
    let url = this.BaseUrl + urls.downloadSingleDocument + '?documentId=' + id
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  downloadAllFiles(laneTaskId){
    let url = this.BaseUrl + urls.downloadAllFiles+laneTaskId;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' })
  }
  searchUploadedTaskDocuments(obj) {
    let url = this.BaseUrl + urls.uploadDocumentSearch
    return this.http.post(url, obj);
  }

  getKanbanUserList() {
    let url = this.BaseCredlixUrl + urls.getUserManagement + '?authorities=' + 'KANBAN'
    return this.http.get(url);
  }

  getRmUserList() {
    let url = this.BaseUrl + urls.getRmUserList + '?isCreditTeams=false';
    return this.http.get(url);
  }
  getRmUserListForCreditTracker() {
    let url = this.BaseUrl + urls.getRmUserList + '?isCreditTeams=true';
    return this.http.get(url);
  }
  getFunnelData(payload: any) {
    let url = this.BaseUrl + urls.getFunnelData;
    return this.http.post(url, payload);
  }
  getFunnelSheetData(payload: any) {
    let url = this.BaseUrl + urls.getFunnelData + '?getLeads=true';
    return this.http.post(url, payload);
  }
  getLaneTaskHistory(id, obj) {
    let url = this.BaseUrl + urls.laneTaskHistory + '?laneTaskId=' + id + '&pageNo=' + obj.from + '&pageSize=' + obj.size
    return this.http.get(url);
  }
  addTaskComment(obj) {
    let url = this.BaseUrl + urls.addComment;
    return this.http.post(url, obj)
  }
  getTaskComment(obj) {
    let url = this.BaseUrl + urls.commentTaskSearch;
    return this.http.post(url, obj)

  }
  saveAuthority(obj) {
    let url = this.BaseUrl + urls.saveAuthority;
    return this.http.post(url, obj)
  }

  uploadCofigDocuments(obj) {
    let url = this.BaseUrl + urls.configDocumentUpload;
    return this.http.post(url, obj)
  }
  uploadBuyerDocumentsById(obj, buyerID, type) {
    let url = this.BaseUrl + urls.uploadBuyerDocs + '?buyerDataId=' + buyerID + '&type=' + type
    return this.http.post(url, obj)
  }

  getConfigCountryList() {
    let url = this.BaseCredlixUrl + urls.getConfigCountry;
    return this.http.get(url)
  }

  readBuyerFileData(obj) {
    let url = this.BaseUrl + urls.readBuyerDataFile + '?documentId=' + obj.documentId + '&limit=' + obj.limit
    return this.http.post(url, {})
  }

  getGeneratedTermSheetData(obj, id) {
    let url = this.BaseUrl + urls.termSheetGenerate + '?lanetaskId=' + id
    return this.http.post(url, obj)
  }

  generateTermSheetPDF(obj, id) {
    let url = this.BaseUrl + urls.termSheetGenerate + '?lanetaskId=' + id
    return this.http.post(url, obj, { observe: 'response', responseType: 'blob' as 'json' })
  }
  ValidateTermSheetPDF(obj, id) {
    let url = this.BaseUrl + urls.validateTermsheet + '?lanetaskId=' + id
    return this.http.post(url, obj)
  }
  saveTermSheetData(obj, id) {
    let url = this.BaseUrl + urls.termSheetSave + '?lanetaskId=' + id
    return this.http.post(url, obj)
  }
  generateRPAPDF(obj, id) {
    let url = this.BaseUrl + urls.rpaSheetGenerate + '?laneTaskId=' + id
    return this.http.post(url, obj, { responseType: 'blob' as 'blob' })
  }
  saveRPA(obj, id) {
    let url = this.BaseUrl + urls.rpaSheetGenerate + '?laneTaskId=' + id
    return this.http.post(url, obj, { responseType: 'blob' as 'json', observe: 'response' })
  }
  generateManualRPAPDF(obj, id) {
    let url = this.BaseUrl + urls.rpaSheetGenerateAuto + '?laneTaskId=' + id
    return this.http.post(url, obj, { responseType: 'blob' as 'blob' })
  }
  rpaFiles(id) {
    let url = this.BaseUrl + urls.rpaFiles + '?laneTaskId=' + id
    return this.http.get(url)
  }
  downloadRPA(id: string) {
    let url = this.BaseUrl + urls.downloadRpaSheet + '?documentId=' + id
    return this.http.get(url, { responseType: 'blob' as 'blob' })
  }
  downloadRPAManual(id) {
    let url = this.BaseUrl + urls.rpaManualDownload + '?documentId=' + id
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  deleteRpa(id) {
    let url = this.BaseUrl + urls.rpaDelete + '?documentId=' + id;
    return this.http.delete(url);
  }
  getRPASheetData(id) {
    let url = this.BaseUrl + urls.getRPAData + '?laneTaskId=' + id
    return this.http.get(url)
  }
  getPrepareTermSheetData(id) {
    let url = this.BaseUrl + urls.getTermSheetData + '?laneTaskId=' + id
    return this.http.get(url)
  }

  updateBuyerData(id, obj) {
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id
    return this.http.put(url, obj)
  }
  getBuyerData(id, tabId) {
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id + "&laneId=" + tabId;
    return this.http.get(url)
  }

  getBuyerDataById(id,) {
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id;
    return this.http.get(url)
  }

  uploadPanjivaFile(obj) {
    let url = this.EximURL + urls.uploadMultipleLead + '?leads=' + 'credlix'
    return this.http.post(url, obj)
  }

  getCountryList() {
    let url = this.BaseCredlixUrl + urls.getCountryList;
    return this.http.get(url)
  }
  CountryList() {
    let url = this.BaseCredlixUrl + urls.country;
    return this.http.get(url)
  }
  deleteTermSheet(id) {
    let url = this.BaseUrl + urls.deleteTermsheet + '/' + id
    return this.http.delete(url);
  }
  deleteBuyer(buyerId, docId) {
    let url = this.BaseUrl + urls.deleteBuyerData + '?buyerdataId=' + buyerId + "&documentIdList=" + docId;
    return this.http.delete(url);
  }

  saveContactDetails(obj, id) {
    let url = this.BaseUrl + urls.contactDetails + '?laneTaskId=' + id
    return this.http.post(url, obj)
  }
  getContactDetails(id) {
    let url = this.BaseUrl + urls.contactDetails + '?laneTaskId=' + id
    return this.http.get(url)
  }
  createBuyerDataRow(obj, id) {
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id
    return this.http.post(url, obj)
  }
  deleteContactDetails(id, contactId) {
    let url = this.BaseUrl + urls.deleteContactDetails + '?contacts=' + contactId + "&laneTaskId=" + id;
    return this.http.delete(url)
  }
  deleteBuyerData(id, buyerID) {
    let url = this.BaseUrl + urls.buyerData + '?buyerDataIds=' + buyerID + "&laneTaskId=" + id;
    return this.http.delete(url)
  }
  getDropdowndata() {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    let url = "https://credlix-public.s3.ap-south-1.amazonaws.com/DropdownData.json";
    return this.http.get(url, { headers });
  }
  generateCAMReport(gstin) {
    let url = this.BaseCredlixUrl + urls.getGstPdf + '?gstin=' + gstin
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' })
  }


  buyerConfigUpdate(obj, id) {
    let url = this.BaseUrl + urls.updateBuyerDataConfig + '?boardId=' + id
    return this.http.post(url, obj)
  }
  changeAccessLaneType(obj) {
    let url = this.BaseUrl + urls.laneAccessType
    return this.http.post(url, obj)
  }
  getAccessType(id, boardId) {
    let url = this.BaseUrl + urls.getAccessByBoardId + '?userId=' + id + '&boardId=' + boardId
    return this.http.get(url)
  }
  uploadSupplierBulkTermsheet(obj, id) {
    let getMasterId = localStorage.getItem("USERINFO")
    let url = this.BaseUrl + urls.supplierBulkTermsheet + '?boardId=' + id
    return this.http.post(url, obj);
  }

  loadPrepareTermsheetPDF(id: string) {
    let url = this.BaseUrl + urls.loadTermsheet + '?termSheetId=' + id
    return this.http.get(url, { responseType: 'blob' as 'blob' });
  }

  approvePrepareTermsheetDocument(source, id) {
    let url = this.BaseUrl + urls.AcceptPreapreTermsheet + '?source=' + source + '&termSheetId=' + id
    return this.http.get(url);
  }

  checkTermsheetStatus(id) {
    let url = this.BaseUrl + urls.termsheetStatus + '?termSheetId=' + id
    return this.http.get(url);
  }
  uploadBuyerBulkTermsheet(obj, id) {
    let getMasterId = localStorage.getItem("USERINFO")
    let url = this.BaseUrl + urls.buyerBulkTermsheet + '?boardId=' + id
    return this.http.post(url, obj);
  }

  getSupplierTermsheetListing(obj) {
    let url = this.BaseUrl + urls.supplierTermsheetData + '?pageNo=' + obj.from + '&size=' + obj.size
    return this.http.get(url)
  }
  downloadSingleLead(id) {
    let url = this.BaseUrl + urls.downloadBulkLead + '?bulkUploadId=' + id;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' })
  }
  downloadZipLead(id) {
    let url = this.BaseUrl + urls.downloadZipBulkLead + '?bulkUploadId=' + id;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' })
  }
  saveOtherInsurance(obj) {
    let url = this.BaseUrl + urls.saveInsurance + '?insurerName=' + obj.insurerName;
    return this.http.post(url, null);
  }
  getOtherInsurance() {
    let url = this.BaseUrl + urls.getOtherInsurance;
    return this.http.get(url);
  }
  getPreQualifiedUserList() {
    let url = this.BaseCredlixUrl + urls.getUserManagement + '?authorities=' + 'VIEW_PRE_QUALIFIED'
    return this.http.get(url);
  }
  requestPanjivaDetails(obj) {
    let url = this.BaseUrl + urls.requestPanjivaDetails
    return this.http.post(url, obj)
  }

  searchPanjivaDetails(obj) {
    let url = this.BaseUrl + urls.searchPanjivaDetails
    return this.http.post(url, obj)
  }

  fetchPanjivaDetails(obj) {
    let url = this.BaseUrl + urls.fetchPanjivaDetails
    return this.http.post(url, obj)
  }

  gePanjiva(obj) {
    let url = this.BaseUrl + urls.buyerBulkTermsheet
    return this.http.post(url, obj);
  }

  deleteInsurance(id) {
    let url = this.BaseUrl + urls.deleteInsurance + '?id=' + id;
    return this.http.delete(url);
  }
  getApprovedBuyerList(obj) {
    let params = new HttpParams();
    if (obj.search) {
      params = params.append('search', JSON.stringify(obj.search));
    }
    params = params.append('page', obj.pageNo);
    params = params.append('limit', obj.pageSize);
    let url = this.BaseUrl + urls.approvedBuyerList;
    return this.http.get(url, { params });
  }
  deleteApprovedBuyer(id) {
    let url = this.BaseUrl + urls.deleteAppBuyer + `/${id}`;
    return this.http.delete(url)
  }
  createApprovedBuyer(obj) {
    let url = this.BaseUrl + urls.createApprovedBuyer;
    return this.http.post(url, obj);
  }
  updateApprovedBuyer(obj) {
    let url = this.BaseUrl + urls.updateApprovedBuyer;
    return this.http.put(url, obj);
  }
  uploadBulkBuyerFile(obj) {
    let url = this.BaseUrl + urls.uploadBuyerFile;
    return this.http.post(url, obj)
  }
  downloadApprovedBuyerData() {
    let url = this.BaseUrl + urls.downloadAppBuyers;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  downloadApprovedTemplate() {
    let url = this.BaseUrl + urls.downloadApprovedBuyerTemplate;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  getMileStoneListForSales() {
    let url = this.BaseUrl + urls.getMileStones + '?isCreditMilestones=false';
    return this.http.get(url)
  }
  getMileStoneList() {
    let url = this.BaseUrl + urls.getMileStones;
    return this.http.get(url)
  }
  getMileStoneListForCredit() {
    let url = this.BaseUrl + urls.getMileStones + '?isCreditMilestones=true';
    return this.http.get(url)
  }

  saveCreditTrackerData(laneTaskID, creditAnalyst, leadunderwriter) {
    let url = this.BaseUrl + urls.saveCreditTracker + '?laneTaskId=' + laneTaskID + '&creditAnalyst=' + creditAnalyst + '&leadUnderwriter=' + leadunderwriter;
    return this.http.post(url, {})
  }
  sentToUser(obj) {
    const url = this.BaseUrl + urls.shareGSTLink;
    return this.http.post(url, obj);
  }
  downloadEximBuyerTemplate() {
    let url = this.BaseUrl + urls.buyerTemplate;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  updateLoginUserDetails(obj) {
    const url = this.BaseUrl + urls.updateLoginUserDetails;
    return this.http.post(url, obj);
  }
  setGstNo(gstNo){
    this.gstNoObservable.emit(gstNo)
  }
  panjivaBuyerList(){
    let url = this.BaseUrl + urls.panjivaBuyerList;
    return this.http.get(url);
  }
  getDefaultSetting(){
    let url = this.BaseUrl + urls.defaultSetting;
    return this.http.get(url);
  }
  getSupplierScore(id){
    let url = this.BaseUrl + urls.scoreCount + `?supplierId=${id}`;
    return this.http.get(url);
  }
  setSupplierDetails(obj) {
    let url = this.BaseUrl + urls.setSupplierData;
    return this.http.post(url, obj);
  }
  saveBuyerScoreById(obj){
    let url = this.BaseUrl + urls.saveBuyerScore;
    return this.http.post(url, obj);
  }
  salesEditDisable(obj){
    let url = this.BaseUrl + urls.salesEditDisable + `?supplierId=${obj.supplierId}&isEditDisable=${obj.isEditDisable}`;
    return this.http.post(url, obj);
  }
  statesOfIndia = [
    { city: 'ANDHRA_PRADESH', name: 'Andhra Pradesh' },
    { city: 'ARUNACHAL_PRADESH', name: 'Arunachal Pradesh' },
    { city: 'ASSAM', name: 'Assam' },
    { city: 'BIHAR', name: 'Bihar' },
    { city: 'CHHATTISGARH', name: 'Chhattisgarh' },
    { city: 'DELHI', name: 'Delhi' },
    { city: 'GOA', name: 'Goa' },
    { city: 'GUJARAT', name: 'Gujarat' },
    { city: 'HARYANA', name: 'Haryana' },
    { city: 'HIMACHAL_PRADESH', name: 'Himachal Pradesh' },
    { city: 'JHARKHAND', name: 'Jharkhand' },
    { city: 'KARNATAKA', name: 'Karnataka' },
    { city: 'KERALA', name: 'Kerala' },
    { city: 'MADHYA_PRADESH', name: 'Madhya Pradesh' },
    { city: 'MAHARASHTRA', name: 'Maharashtra' },
    { city: 'MANIPUR', name: 'Manipur' },
    { city: 'MEGHALAYA', name: 'Meghalaya' },
    { city: 'MIZORAM', name: 'Mizoram' },
    { city: 'NAGALAND', name: 'Nagaland' },
    { city: 'ODISHA', name: 'Odisha' },
    { city: 'PUNJAB', name: 'Punjab' },
    { city: 'RAJASTHAN', name: 'Rajasthan' },
    { city: 'SIKKIM', name: 'Sikkim' },
    { city: 'TAMIL_NADU', name: 'Tamil Nadu' },
    { city: 'TELANGANA', name: 'Telangana' },
    { city: 'TRIPURA', name: 'Tripura' },
    { city: 'UTTAR_PRADESH', name: 'Uttar Pradesh' },
    { city: 'UTTARAKHAND', name: 'Uttarakhand' },
    { city: 'WEST_BENGAL', name: 'West Bengal' },
    { city: 'ANDAMAN_AND_NICOBAR', name: 'Andaman and Nicobar Islands' },
    { city: 'CHANDIGARH', name: 'Chandigarh' },
    { city: 'DADRA_NAGAR_HAVELI_AND_DAMAN_DIU', name: 'Dadra and Nagar Haveli and Daman and Diu' },
    { city: 'JAMMU_AND_KASHMIR', name: 'Jammu And Kashmir' },
    { city: 'LAKSHADWEEP', name: 'Lakshadweep' },
    { city: 'LADAKH', name: 'Ladakh' },
    { city: 'PUDUCHERRY', name: 'Puducherry' }
  ]
}



