import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LandingComponent } from './components/landing/landing.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentComponent } from './pages/pages-init/document/document.component';
import { DocumentViewComponent } from './pages/pages-init/document/document-view/document-view.component';
import { PODocumentViewComponent } from './pages/pages-init/document/podocument-view/podocument-view.component';
import { ListingComponent } from './component/suppliers/listing/listing.component';
import { CommissionInvoicesComponent } from './component/commission-invoices/commission-invoices/commission-invoices.component';
import { SupplierProfileComponent } from './component/suppliers/listing/supplier-profile/supplier-profile.component';
import { EscrowPaymentComponent } from './component/escrow-payment/escrow-payment/escrow-payment.component';
import { AccountAuthorizationComponent } from './auth/account-authorization/account-authorization/account-authorization.component';
import { AnchorDiscountDnComponent } from './component/anchor-discount-dn/anchor-discount-dn.component';
import { TermsheetApproveLinkComponent } from './pages/termsheet-approve-link/termsheet-approve-link.component';
import { LedgerReportComponent } from './component/ledger-report/ledger-report.component';
import { ApprovedBuyerListComponent } from './component/credlix-board/k-board/board-lane/card-details/approved-buyer-list/approved-buyer-list.component';
import { SendForConsentComponent } from './auth/send-for-consent/send-for-consent.component';
import { ConsentOtpComponent } from './auth/consent-otp/consent-otp.component';

const routes: Routes = [
  // { path: '',loadChildren: './auth/auth.module#AuthModule'},
  { path: '', component: LandingComponent, canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'pages', loadChildren: './pages/pages.module#PagesModule', canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsModule', canActivate: [AuthGuard] },
  { path: 'OnBoard', loadChildren: './document-verification/document-verification.module#DocumentVerificationModule' },
  { path: 'suppliers', loadChildren: './component/suppliers/suppliers.module#SuppliersModule', canActivate: [AuthGuard] },
  { path: 'sid', loadChildren: './component/sid/sid.module#SIDModule', canActivate: [AuthGuard] },
  { path: 'anchor', loadChildren: './component/anchor-finance/anchor-finance.module#AnchorFinanceModule', canActivate: [AuthGuard] },
  { path: 'vendor', loadChildren: './component/vendor-finance/vendor-finance.module#VendorFinanceModule', canActivate: [AuthGuard] },
  { path: 'vendor-supplier', loadChildren: './component/vendor-finance-supplier/vendor-finance-supplier.module#VendorFinanceSupplierModule', canActivate: [AuthGuard] },
  // {path: 'earlyPayments', loadChildren: './component/early-payments/early-payments.module#EarlyPaymentsModule'},

  
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    component: DocumentComponent,
    canActivate: [AuthGuard]
  },
  { path:'sent-for-consent/:id', component: SendForConsentComponent },
  { path: 'consent-otp-verification/:id', component: ConsentOtpComponent },
  {
    path: 'termsheet',
    component: TermsheetApproveLinkComponent
  },
  {
    path: 'documentView/invoice/:id',
    component: DocumentViewComponent
  },
  {
    path: 'documentView/supplier-po/:id',
    component: PODocumentViewComponent
  },

  {
    path: 'supplierLinks',
    component: ListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplierLinks/:id',
    component: ListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path : 'suppliers/discount-debit-notes',
    component : AnchorDiscountDnComponent
  },
  {
    path: 'commission-invoices',
    component: CommissionInvoicesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplierProfile/:id',
    component: SupplierProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'escrow-payment',
    component: EscrowPaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ledger-report',
    component: LedgerReportComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'presales', loadChildren: './component/pre-sales/pre-sales.module#PreSalesModule',
  //   canActivate: [AuthGuard] 
  // },
  {
    path: 'select-account',
    component: AccountAuthorizationComponent,
    canActivate: [AuthGuard]
  },
  {
      path: 'approvedBuyers',
      component : ApprovedBuyerListComponent,
      canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
