<div class="d-flex justify-content-between sendBoxHead">
	<h2>Send Consent Approval to</h2>
	<button mat-dialog-close class="cancel"><i class="ri-close-line"></i></button>
</div>
<div class="d-flex justify-content-end sentBoxCta">
	<button (click)="sendForConsent()">Send For Consent</button>
</div>
<div class="sendTable mat-elevation-z8">
	<table mat-table [dataSource]="dataSource" class="w-full">

		<ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleAllRows($event)" [checked]="isAllSelected()" [indeterminate]="isSomeSelected()"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox (change)="toggleRow(element)" [checked]="selection.isSelected(element)"></mat-checkbox>
      </td>
    </ng-container>

		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Director Name</th>
			<td mat-cell *matCellDef="let element">{{ element.name }}</td>
		</ng-container>

		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef>Director Email</th>
			<td mat-cell *matCellDef="let element">{{ element.email }}</td>
		</ng-container>

		<ng-container matColumnDef="dse">
			<th mat-header-cell *matHeaderCellDef>Wheather DSE Registered</th>
			<td mat-cell *matCellDef="let element">{{ element.wheatherDscRegistered }}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
	</table>
</div>
