import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-send-for-consent',
  templateUrl: './send-for-consent.component.html',
  styleUrls: ['./send-for-consent.component.scss']
})
export class SendForConsentComponent implements OnInit {
  showThankYou:boolean=false;
  constructor(private activatedRoute:ActivatedRoute,private router:Router) { }
  laneTaskId:any;
  ngOnInit() {
    this.activatedRoute.params.subscribe(resp=>{
      this.laneTaskId = resp.id;
    })
  }
  consent(){
    this.router.navigate(['/consent-otp-verification/',this.laneTaskId])
  }
  cancel(){
    this.showThankYou = !this.showThankYou;
  }
}
